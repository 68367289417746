import React, { useState } from "react";
import { Button } from "react-bootstrap";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { useNavigate } from "react-router-dom";
import ForgotPasswordAPI from "../../Api/ForgotPasswordAPI";
import { LOGIN_URL } from "../Shared/constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BtnLoader from "../Common/BtnLoader";

const ForgotPassword = () => {
    const Navigate = useNavigate();
    const [ inputValue, setInputValue ] = useState({ Email : "" });
    const [ loader, setloader ] = useState(false);
    const { Email } = inputValue;

    // Regex
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // create password function
    const CreatePassword = async () => {
        if(inputValue?.Email !== "") {
            if(EmailRegex.test(inputValue?.Email) === true) {
                setloader(true);
                const jsonData = JSON.stringify({ email: inputValue?.Email });
                const response = await ForgotPasswordAPI(jsonData);
                if(response?.status === 200){
                    setloader(false);
                    toast.success(response.msg);
                    setTimeout(() => Navigate(LOGIN_URL), 200);
                    clearForm();
                } else {
                    setloader(false);
                    clearForm();
                    toast.error(response.error);
                }
            } else {
                CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
            }
        } else {
            CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
        }
    };

    // clear form
    const clearForm = () => {
        setInputValue({...inputValue, Email : ""});
        setErrorEmail("");
    };

    return(<section className="login_wrap">
        <div className="login_form">
            <div className="login_Header">
                <h1>Forgot Password ?</h1>
            </div>
            <div className="login_form_main">
                <InputField
                    type="text"
                    value={Email}
                    className={errorEmail !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter email"
                    label="Email"
                    name="Email"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorEmail}
                />
                <div className="form-group-btn">
                    <Button className="cmn_bigbtn" onClick={() => CreatePassword()}>
                        {loader && <BtnLoader />} Create Password
                    </Button>
                </div>
            </div>
        </div>
    </section>)
};

export default ForgotPassword;