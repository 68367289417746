/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Dashbaord from "./ViewVendorTab/Dashbaord";
import Category from "./ViewVendorTab/Category";
import CustomersList from "./ViewVendorTab/CustomersList";
import Orders from "./ViewVendorTab/Orders";
import Product from "./ViewVendorTab/Product";
import { VENDOR_MANAGEMENT_URL } from "../Shared/constant";
import GetVendorDetailsAPI from "../../Api/GetVendorDetailsAPI";
import CommonLogout from "../Common/CommonLogout";
import DataLoader from "../Common/DataLoader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";

const ViewVendor = () => {
    const UserPar = useParams();
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const [ ViewTab, setViewTab ] = useState("Dashboard");
    const { accessToken } = useSelector((state) => state.adminReducers);
    const [ APILoader, setAPILoader ] = useState(false);
    const [ SelectedVendor, setSelectedVendor ] = useState({});
    const { websocket } = useContext(WebSocketContext);

    const [ allowTab, setAllowTab ] = useState({
        has_category: true,
        has_customer: true,
    });

     // get data for edit vendor
     useEffect(() => {
        GetVendorDetails();
    }, [ UserPar?.roomId ]);

    // get vendor details api
    const GetVendorDetails = async () => {
        setAPILoader(true);
        const response = await GetVendorDetailsAPI(accessToken, UserPar?.roomId);
        if(response?.status === 200){
            setTimeout(() => {
                setAPILoader(false);
                setSelectedVendor(response?.data);
                setAllowTab({...allowTab, has_category: response?.data?.has_category, has_customer: response?.data?.has_customer})
            }, 200);
        } else if(response?.status === 403){
            setAPILoader(false);
            setSelectedVendor({});
            setAllowTab({...allowTab, has_category: true, has_customer: true})
            CommonLogout(accessToken, Dispatch);
        } else {
            setAPILoader(false);
            setSelectedVendor({});
            toast.error(response.error);
        };
    };

    return(<div className="vendor_view_sections">
        <ul className="tabs_content">
            <li className={ViewTab === "Dashboard" ? "active" : ""} onClick={() => setViewTab("Dashboard")}>Dashboard</li>
            {allowTab?.has_category && <li className={ViewTab === "Category" ? "active" : ""} onClick={() => setViewTab("Category")}>Category</li>}
            <li className={ViewTab === "Product" ? "active" : ""} onClick={() => setViewTab("Product")}>Product</li>
            <li className={ViewTab === "Orders" ? "active" : ""} onClick={() => setViewTab("Orders")}>Orders</li>
            {allowTab?.has_customer && <li className={ViewTab === "Customers-list" ? "active" : ""} onClick={() => setViewTab("Customers-list")}>Customers list</li>}
            <div className="backbtn" onClick={() => Navigate(VENDOR_MANAGEMENT_URL)}><i className="bi bi-arrow-90deg-left"></i>Back</div>
        </ul>
        <div className="tabs_content_wrap">
            {ViewTab === "Dashboard" && (<Dashbaord setViewTab={setViewTab} SelectedVendor={SelectedVendor} UserPar={UserPar} />)}
            {ViewTab === "Category" && (<Category websocket={websocket} UserPar={UserPar} wsSend_request={wsSend_request}/>)}
            {ViewTab === "Product" && (<Product websocket={websocket} UserPar={UserPar} wsSend_request={wsSend_request}/>)}
            {ViewTab === "Orders" && (<Orders websocket={websocket} UserPar={UserPar} wsSend_request={wsSend_request}/>)}
            {ViewTab === "Customers-list" && (<CustomersList websocket={websocket} UserPar={UserPar} wsSend_request={wsSend_request}/>)}
        </div>
        {APILoader && (<DataLoader />)}
    </div>)
}

export default ViewVendor;