/* eslint-disable */
import React from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ADD_NEW_VENDOR_URL, DASHBOARD_URL, DEVICES_LIST_URL, EDIT_VENDOR_URL, USER_MANAGEMENT_URL, VENDOR_MANAGEMENT_URL, VIEW_VENDOR_URL } from "./constant";

const Sidebar = () => {
    const Location = '/'+useLocation()?.pathname.split("/")[1];
    const { sidebaropen } = useSelector((state) => state.adminReducers); 

    return(<div className={sidebaropen ? "sidebar active" : "sidebar"}>
        <div className="navbarHeader">
            {!sidebaropen ? (<h1>Logo</h1>) : (<h1>L</h1>)}
        </div>
        <Scrollbars 
            style={{ height: "calc(100vh - 72px)" }} 
            renderView={props => <div {...props} className="view"/>}
            className="ScrollbarsSidebar"
        >
            <Nav defaultActiveKey={DASHBOARD_URL} className="flex-column">
                <Link to={DASHBOARD_URL} className={(Location === DASHBOARD_URL || Location === "/") ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-speedometer2"></i>
                    <span>Dashboard</span>
                </Link>
                {/* <Link to={USER_MANAGEMENT_URL} className={(Location === USER_MANAGEMENT_URL || Location === "/") ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-people"></i>
                    <span>User Management</span>
                </Link> */}
                <Link to={VENDOR_MANAGEMENT_URL} className={(Location === VENDOR_MANAGEMENT_URL || Location === EDIT_VENDOR_URL || Location === ADD_NEW_VENDOR_URL || Location === VIEW_VENDOR_URL || Location === "/") ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-shop-window"></i>
                    <span>Vendor Management</span>
                </Link>
                <Link to={DEVICES_LIST_URL} className={(Location === DEVICES_LIST_URL || Location === "/") ? "nav-link active" : "nav-link"}>
                    <i className="bi bi-display"></i>
                    <span>Device</span>
                </Link>
            </Nav>
        </Scrollbars>
    </div>)
};

export default Sidebar;