// empty space validation fucntion
const CheckValid = (value, states) => {
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

    // email validation
    if(states?.type === "email") {
        if(value !== "") {
            if(EmailRegex.test(value) === true) {
                return states?.error("");    
            } else {
                if(EmailRegex.test(value) === false) {
                    return states?.error("Please Enter Valid Email id");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if(value === "") {
                return states?.error("Email id cannot be empty");
            } else if(EmailRegex.test(value) === false) {
                return states?.error("Please Enter Valid Email id");
            } else {
                return states?.error("");
            }
        };
    };

    // password validation
    if(states?.type === "password"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Please Enter Valid Password");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Please Enter Valid Password");
            } else {
                return states?.error("");
            }
        }
    }

    // old password validation
    if(states?.type === "oldpassword"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Please Enter Valid Password");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Old Password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Please Enter Valid Password");
            } else {
                return states?.error("");
            }
        }
    }

    // new password validation
    if(states?.type === "newpassword"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Please Enter Valid Password");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("New Password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Please Enter Valid Password");
            } else {
                return states?.error("");
            }
        }
    }

    // first name validation
    if(states?.type === "Fname"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("First name cannot be empty");
        }
    }

    // last name validation
    if(states?.type === "Lname"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Last name cannot be empty");
        }
    }

    // Business name validation
    if(states?.type === "Business_name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Business name cannot be empty");
        }
    }

    // Address validation
    if(states?.type === "Address"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Address cannot be empty");
        }
    }

    // phone validation
    if(states?.type === "phone"){
        if(value !== "") {
            if(value.length === "10") {
                return states?.error("");
            } else {
                if(value.length < 10) {
                    return states?.error("Please enter 10 digit phone number");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Phone number cannot be empty");
            } else if(value.length < 10) {
                return states?.error("Please enter 10 digit phone number");
            } else {
                return states?.error("");
            }
        }
    }
};

export default CheckValid;