import React, { useState } from "react";
import { Button } from "react-bootstrap";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { useNavigate, useParams } from "react-router-dom";
import CreatePasswordAPI from "../../Api/CreatePasswordAPI";
import { LOGIN_URL } from "../Shared/constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BtnLoader from "../Common/BtnLoader";

const ResetPassword = () => {
    const Navigate = useNavigate();
    const [ inputValue, setInputValue ] = useState({ Password: "" });
    const [ loader, setloader ] = useState(false);
    const { Password } = inputValue;
    const [ showpassword, setshowpassword ] = useState(false);
    const createPasswordId = useParams()?.roomId;

    // Regex
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

    // error message
    const [ errorPassword, setErrorPassword ] = useState("");
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // show password
    const ShowHidePassword = () => {
        setshowpassword(!showpassword);
    };

    // create password function
    const CreatePassword = async () => {
        if(inputValue?.Password !== "") {
            if(PasswordRegex.test(inputValue?.Password) === true) {
                setloader(true);
                const jsonData = JSON.stringify({ password: inputValue?.Password });
                const response = await CreatePasswordAPI(jsonData, createPasswordId);
                if(response?.status === 200){
                    setloader(false);
                    toast.success(response.msg);
                    setTimeout(() => Navigate(LOGIN_URL), 200);
                    clearForm();
                } else {
                    setloader(false);
                    clearForm();
                    toast.error(response.error);
                }
            } else {
                CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
            }
        } else {
            CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
        }
    };

    // clear form
    const clearForm = () => {
        setInputValue({...inputValue, Password : ""});
        setErrorPassword("");
    };

    return(<section className="login_wrap">
        <div className="login_form">
            <div className="login_Header">
                <h1>Reset Password</h1>
            </div>
            <div className="login_form_main">
                <InputField
                    type={showpassword ? "text" : "password"}
                    value={Password}
                    className={errorPassword !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter password"
                    label="Password"
                    name="Password"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'password', error: setErrorPassword})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorPassword}
                    onClick={ShowHidePassword}
                    showpassword={showpassword}
                />
                <div className="form-group-btn">
                    <Button className="cmn_bigbtn" onClick={() => CreatePassword()}>
                        {loader && <BtnLoader />} Ok
                    </Button>
                </div>
            </div>
        </div>
    </section>)
};

export default ResetPassword;