export const ActionTypes = {
    IS_LOGIN: "IS_LOGIN",
    GET_USER_ID: "GET_USER_ID",
    SIDEBAR_CLOSE: "SIDEBAR_CLOSE",
    SELF_DETAILS: "SELF_DETAILS",
    GET_ACCESSTOKEN: "GET_ACCESSTOKEN",
    ADMIN_LOGOUT: "ADMIN_LOGOUT",
    EDIT_SELF_DETAILS: "EDIT_SELF_DETAILS",
    GET_SESSION_LIST: "GET_SESSION_LIST",
    DELETE_SESSION_LIST: "DELETE_SESSION_LIST",
    GET_DASHBOARD_COUNT: "GET_DASHBOARD_COUNT"
};

export const checkLogin = (status) => {
    return {
        type: ActionTypes.IS_LOGIN,
        payload: status,
    }
}

export const getDashBoardCount = (dashboard) => {
    return {
        type: ActionTypes.GET_DASHBOARD_COUNT,
        payload: dashboard,
    }
}

export const getUserId = (id) => {
    return {
        type: ActionTypes.GET_USER_ID,
        payload: id,
    }
}

export const sidebarClose = (sidebarstatus) => {
    return {
        type: ActionTypes.SIDEBAR_CLOSE,
        payload: sidebarstatus,
    }
};

export const getSelfDetails = (user) => {
    return {
        type: ActionTypes.SELF_DETAILS,
        payload: user,
    }
}

export const editSelfDetails = (user) => {
    return {
        type: ActionTypes.EDIT_SELF_DETAILS,
        payload: user,
    }
}

export const getAccessToken = (token) => {
    return {
        type: ActionTypes.GET_ACCESSTOKEN,
        payload: token,
    }
}

export const getSessionLists = (session) => {
    return {
        type: ActionTypes.GET_SESSION_LIST,
        payload: session,
    }
}

export const deleteSessionList = (session) => {
    return {
        type: ActionTypes.DELETE_SESSION_LIST,
        payload: session,
    }
}

export const clearRedux = () => {
    return {
       type: ActionTypes.ADMIN_LOGOUT,
    }
}