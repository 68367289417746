/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DataTableFilter from "../../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from "moment";
import CommonPagination from "../../Common/CommonPagination";

const CustomersList = (props) => {
    const { wsSend_request, UserPar, websocket } = props;
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { customerlist } = useSelector((state) => state.vendorReducers);

     // select user type
     const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        getCustomerDetails();
    }, [ userLimit, currentPage, UserPar?.roomId, userSearch ]);

    // get realtime data
    const getCustomerDetails = () => {
        let param = { 
            "transmit": "broadcast", 
            "url": "customer_list", 
            "request" :  { 
                "vendor_id" : UserPar?.roomId,
                "is_pagination":true, 
                "limit" : parseInt(userLimit), 
                "page_no": currentPage,
                "search": userSearch
            }
        };
        wsSend_request(websocket, param);
    };

    return(<>
        <DataTableFilter 
            filterType={"Vendor Customer"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
        />
        <div className="tableContent" style={{ height: "calc(100% - 65px)" }}>
            <Scrollbars 
                style={{ height: customerlist?.pagination?.length > 10 ? "calc(100% - 0px)" : "calc(100% - 0px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customerlist && customerlist?.list?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                <tr>
                                    <td>{user?.user_name == null ? "-": user?.user_name}</td>
                                    <td>{user?.phone === null ? "-" : "+"+user?.phone_code + " " + user?.phone}</td>
                                    <td>{moment(user.created_at).format('LL')}</td>
                                </tr>
                            </React.Fragment>)})}
                            {customerlist && customerlist?.list?.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Vendor customer Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
        </div>
        {customerlist?.pagination?.total_records > 10 && (<CommonPagination 
            currentPage={currentPage}
            paginationLength={customerlist?.pagination?.length}
            currentFunction={currentFunction}
        />)}
    </>)
}

export default CustomersList;