/* eslint-disable */
import { ActionTypes } from "../actions/vendorActions";

const initailData = {
    vendorlist: [],
    vendorlistidwise: {},
    customerlist: {
        list: [],
        pagination: {}
    },
    productlist: {
        list: [],
        pagination: {}
    },
    categorylist: {
        list: [],
        pagination: {},
        categoryIdwise: {}
    },
    orderlist: {
        list: [],
        pagination: {}
    },
    vendordashboardDetails: {},
};

export const vendorReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_VENDOR_DASHBOARD_COUNT:
            return {
                ...state,
                vendordashboardDetails: action.payload,
            };
        case ActionTypes.GET_VENDOR_LIST:
            const VendorIdwiseArray = {};
            action?.payload && action?.payload?.map((elm) => {
                VendorIdwiseArray[elm?.id] = elm?.first_name + " " + elm?.last_name;
            });
            return {
                ...state,
                vendorlist: action.payload,
                vendorlistidwise: VendorIdwiseArray
            };
        case ActionTypes.ADD_VENDOR:
            const oldVendorlist = [...state.vendorlist];
            oldVendorlist.unshift(action.payload);
            return {
                ...state,
                vendorlist: oldVendorlist,
            };
        case ActionTypes.DELETE_VENDOR_LIST:
            const oldVendorlistState = [...state.vendorlist];
            const DeleteUpdatedVendorList = []
            oldVendorlistState && oldVendorlistState.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return DeleteUpdatedVendorList.push(elm);
                }
            })
            return {
                ...state,
                vendorlist: DeleteUpdatedVendorList,
            };
        case ActionTypes.UPDATE_VENDOR:
            const oldVendorlists = [...state.vendorlist];
            const oldVendorlistUpdate = oldVendorlists.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return {
                        ...elm,
                        first_name: action.payload.first_name,
                        last_name: action.payload.last_name,
                        email: action.payload.email,
                        phone_code: action.payload.phone_code,
                        phone: action.payload.phone,
                        address : action.payload.address,
                        business_name : action.payload.business_name,
                        has_customer : action.payload.has_customer,
                        has_discount : action.payload.has_discount,
                        has_category : action.payload.has_category,
                        is_admin: action.payload.is_admin,
                        is_vendor: action.payload.is_vendor,
                        is_active : action.payload.is_active,
                    }
                } else 
                return elm;
            })
            return {
                ...state,
                vendorlist: oldVendorlistUpdate,
            };
        case ActionTypes.GET_VENDOR_CUSTOMER_LIST:
            return {
                ...state,
                customerlist: {
                    list: action.payload.data === undefined ? [] : action.payload.data,
                    pagination: action.payload.pagination === undefined ? [] : action.payload.pagination
                },
            };
        case ActionTypes.GET_VENDOR_PRODUCT_LIST:
            return {
                ...state,
                productlist: {
                    list: action.payload.data === undefined ? [] : action.payload.data,
                    pagination: action.payload.pagination === undefined ? [] : action.payload.pagination
                },
            };
        case ActionTypes.GET_VENDOR_CATEGORY_LIST:
            const CatgoryIdwiseArray = {};
            action?.payload && action?.payload?.data?.map((elm) => {
                CatgoryIdwiseArray[elm?.id] = elm?.name;
            });

            return {
                ...state,
                categorylist: {
                    list: action.payload.data === undefined ? [] : action.payload.data,
                    pagination: action.payload.pagination === undefined ? [] : action.payload.pagination,
                    categoryIdwise: CatgoryIdwiseArray
                },
            };
        case ActionTypes.GET_VENDOR_ORDER_LIST:
            return {
                ...state,
                orderlist: {
                    list: action.payload.data === undefined ? [] : action.payload.data,
                    pagination: action.payload.pagination === undefined ? [] : action.payload.pagination,
                },
            };
        default:
        return state;
    }
};