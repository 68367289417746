/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import Chrome_Icon from "../../assets/img/browsers/chrome.png";
import Firefox_Icon from "../../assets/img/browsers/Firefox.png";
import Internet_Explorer_Icon from "../../assets/img/browsers/internet_explorer.png";
import Microsoft_Icon from "../../assets/img/browsers/microsoft.png";
import Opera_Icon from "../../assets/img/browsers/opera.png";
import Safari_Icon from "../../assets/img/browsers/safari.png";
import moment from "moment";
import { Scrollbars } from 'react-custom-scrollbars-2';

const Device = () => {
    const { websocket } = useContext(WebSocketContext);
    const { sessionlist } = useSelector((state) => state.adminReducers);

    useEffect(() => {
        GetSessionList();
    }, [  ]);

    const GetSessionList = () => {
        let param = {"transmit": "broadcast", "url": "session_list"};
        wsSend_request(websocket, param);
    };

    const DeactivateSession = (session) => {
        let param = {"transmit": "broadcast", "url": "session_logout", "request" : {"token_id" : session?.id}};
        wsSend_request(websocket, param);
    };

    return(<section className="deviceswrapper">
        <div className="tableContent" style={{ height: "calc(100% - 0px)" }}>
            <Scrollbars 
                style={{ height: "calc(100% - 0px)"}} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <ul>
                {sessionlist && sessionlist?.map((elm, index) => {
                        const BroserName = elm?.user_agent?.split(",")[1].replace(")", "").replace("'", "").replace("'", "");
                        const OsName = elm?.user_agent?.split(",")[0].replace("(", "").replace("'", "").replace("'", "");
                        const BrowserIcon = BroserName.split(" ")[1];

                        return(<li key={index}>
                            <span className="BrowserImage">
                                <img src={BrowserIcon === "Chrome" ? Chrome_Icon : 
                                        BrowserIcon === "Firefox" ? Firefox_Icon : 
                                        BrowserIcon === "Safari" ? Safari_Icon :
                                        BrowserIcon === "Internet" ? Internet_Explorer_Icon : 
                                        BrowserIcon === "ChromiumEdge" ? Microsoft_Icon : Opera_Icon} alt={BrowserIcon} />
                            </span>
                            <div className="browserDetails">
                                <h4>{BroserName} ( {OsName} )</h4>
                                <span>Last active {moment(elm.updated_at).format('LL')}</span>
                            </div> 
                            <button className="Deactivatebtn" onClick={() => DeactivateSession(elm)}>
                                <i className="bi bi-box-arrow-right"></i>
                            </button>
                        </li>)
                })}
                {sessionlist && sessionlist?.length === 0 && <li style={{ justifyContent: "center" }} >Device list not found.</li>}
                </ul>
            </Scrollbars>
        </div>
    </section>)
};

export default Device;