import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sidebarClose } from "../../redux/actions/adminActions";
import MenuHide from "../../assets/img/menu_hide.png";
import MenuFull from "../../assets/img/menu_full.png";
import { Image } from "react-bootstrap";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import AllowPermissionModal from "../Common/AllowPermissionModal";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PROFILE_URL } from "./constant";

const Header = () => {
    const dispatch = useDispatch();
    const Location = useLocation();
    const Navigate = useNavigate();
    const UserPar = useParams();
    const { sidebaropen, selfInfo } = useSelector((state) => state.adminReducers);
    const { vendorlist } = useSelector((state) => state.vendorReducers);
    const [ showPermisson, setShowPermisson ] = useState({
        open: false,
        title: "",
        modalType: {
            type: "",
            action: "",
        },
        button: "",
        Data: {}
    });

    const PageNameGet = () => {
        if(Location.pathname.replace("/", "").replace("-", " ").split("/")[0] === "view vendor") {
            return vendorlist.filter((elm) => elm?.id === UserPar?.roomId)[0]?.first_name + " " + vendorlist.filter((elm) => elm?.id === UserPar?.roomId)[0]?.last_name;
        } else {
            return Location.pathname.replace("/", "").replace("-", " ").split("/")[0];
        };
    };

    // vendor allow all permission
    const VendorPermissionAllow = () => {
        setShowPermisson({...showPermisson, 
            title: "Are you sure?", 
            open: !showPermisson.open, 
            modalType: {
                type: "logout",
                action: "",
            }, 
            button: "Yes",
            description: `<h4 class="logout_text"><i class="bi bi-box-arrow-right"></i> Do you really want to logout</h4>`
        });
    };

    return(<div className="header_common">
        <button type="button" className="toggle-btn" onClick={() => dispatch(sidebarClose(!sidebaropen))}>
            {sidebaropen ? (<Image src={MenuHide} alt="full hide"/>) : (<Image src={MenuFull} alt="full full"/>)}
        </button>
        <h4>{PageNameGet()}</h4>
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">Update Profile</Tooltip>}
        >
            <div className="dropdownwrps" onClick={() => Navigate(PROFILE_URL)}>
                <i className="bi bi-person-circle"></i>
                <div className="userDetails">
                    <h4>{(selfInfo?.first_name !== null && selfInfo?.last_name !== null) ? selfInfo?.first_name + " " + selfInfo?.last_name : selfInfo.email}</h4>
                </div>
            </div>
        </OverlayTrigger>
        <button type="button" onClick={() => VendorPermissionAllow()} className="logoutbtn"><i className="bi bi-box-arrow-right"></i></button>
        {showPermisson?.open && (<AllowPermissionModal showPermisson={showPermisson} setShowPermisson={setShowPermisson} />)}
    </div>)
};

export default Header;