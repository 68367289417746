/* eslint-disable */
import React, { useEffect } from "react";
import VendorIcon from "../../../assets/img/vendor.png";
import CategoryIcon from "../../../assets/img/category.png";
import PorductIcon from "../../../assets/img/product.png";
import OrderIcon from "../../../assets/img/order.png";
import CustomerIcon from "../../../assets/img/customer.png";
import { Image } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from "moment/moment";
import VendorDashBoardAPI from "../../../Api/VendorDashBoardAPI";
import { getVendorDashBoardCount } from "../../../redux/actions/vendorActions";
import { useDispatch, useSelector } from "react-redux";
import CommonLogout from "../../Common/CommonLogout";

const Dashbaord = (props) => {
    const { SelectedVendor, setViewTab, UserPar } = props;
    const { accessToken } = useSelector((state) => state.adminReducers);
    const { vendordashboardDetails } = useSelector((state) => state.vendorReducers);
    const Dispatch = useDispatch();

    useEffect(() => {
        if (UserPar?.roomId) {
            fetchData();
        }
    }, [UserPar?.roomId]);
    

    const fetchData = async () => {
        // Get dashboard count
        const dashboardList = await VendorDashBoardAPI(accessToken, UserPar?.roomId);
        if (dashboardList?.status === 200) {
            Dispatch(getVendorDashBoardCount(dashboardList))
        } else if (dashboardList?.detail === "Token expired.") {
            CommonLogout();
        } else if (dashboardList?.detail === "User is inactive.") {
            CommonLogout();
        } else {
            // toast.error(categoryList.data.error);
        };
    };

    useEffect(() => {
        const google = window.google;
        google.charts.load('current', {'packages':['corechart', 'bar']});
        google.charts.setOnLoadCallback(drawChart);
        google.charts.setOnLoadCallback(drawChartProduct);
        google.charts.setOnLoadCallback(drawChartOrders);
        google.charts.setOnLoadCallback(drawChartCustomerChart);

        function drawChart() {
            var data = google.visualization.arrayToDataTable([
              ['Task', 'Hours per Day'],
              ['Category', 11],
              ['Customer', 2],
              ['Discount', 2]
            ]);
      
            var options = {
              title: '',
              is3D: true
            };
      
            var chart = new google.visualization.PieChart(document.getElementById('piechart'));
            chart.draw(data, options);
          };
      
      
        // six months product list********************************
        const getLastSixMonth = [];
        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var today = vendordashboardDetails?.past_six_month_product_count?.past_six_month_count.length > 0 ? new Date(vendordashboardDetails?.past_six_month_product_count?.past_six_month_count[vendordashboardDetails?.past_six_month_product_count?.past_six_month_count?.length - 1]?.month) : new Date();
        var d;
        var month;
        for(var i = 6; i >= 0; i -= 1) {
            d = new Date(today.getFullYear(), today.getMonth() - i, 1);
            month = monthNames[d.getMonth()];
            getLastSixMonth.push(`${month} 1, ${moment(d).format('YYYY')}`);
        };
        var EmptyMonthArray = [
            [getLastSixMonth[0].split(" ")[0], 1],
            [getLastSixMonth[1].split(" ")[0], 1],
            [getLastSixMonth[2].split(" ")[0], 1],
            [getLastSixMonth[3].split(" ")[0], 1],
            [getLastSixMonth[4].split(" ")[0], 1],
            [getLastSixMonth[5].split(" ")[0], 1],
            [getLastSixMonth[6].split(" ")[0], 1]
        ];
        function drawChartProduct() {
            const PastSixPamentArrays = [['Year', 'count']];
            if(vendordashboardDetails?.past_six_month_product_count?.past_six_month_count.length > 0) {
                getLastSixMonth.filter((data_, index) => {
                    const CurrentBar = vendordashboardDetails?.past_six_month_product_count?.past_six_month_count[index];
                    if(data_.split(" ")[0] !== moment.utc(CurrentBar?.month).local().format('LL').split(" ")[0]) {
                        PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), 0]);    
                    } else {
                        vendordashboardDetails?.past_six_month_product_count?.past_six_month_count.filter((elm) => {
                            PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), elm?.count]);
                        })
                    }
                });
            } else {
                getLastSixMonth.filter((data_, index) => {
                    PastSixPamentArrays.push([data_, 0]);
                });
            }
    
            var data = google.visualization.arrayToDataTable(PastSixPamentArrays);
            
            var options = {
                chart: {
                    // title: 'Company Performance',
                    // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                }
            };
            var chart = new google.charts.Bar(document.getElementById('columnchart_material'));
            chart.draw(data, google.charts.Bar.convertOptions(options));
        };
      
      
        // six months orders list********************************
        const getLastSixMonthOrder = [];
        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var todays = vendordashboardDetails?.past_six_month_order_count?.past_six_month_count.length > 0 ? new Date(vendordashboardDetails?.past_six_month_order_count?.past_six_month_count[vendordashboardDetails?.past_six_month_order_count?.past_six_month_count?.length - 1]?.month) : new Date();
        var ds;
        var month;
        for(var i = 6; i >= 0; i -= 1) {
            ds = new Date(todays.getFullYear(), todays.getMonth() - i, 1);
            month = monthNames[ds.getMonth()];
            getLastSixMonthOrder.push(`${month} 1, ${moment(ds).format('YYYY')}`);
        };
        function drawChartOrders() {
            const PastSixPamentArrays = [['Year', 'count']];
            if(vendordashboardDetails?.past_six_month_order_count?.past_six_month_count.length > 0) {
                getLastSixMonthOrder.filter((data_, index) => {
                    const CurrentBar = vendordashboardDetails?.past_six_month_order_count?.past_six_month_count[index];
                    if(data_.split(" ")[0] !== moment.utc(CurrentBar?.month).local().format('LL').split(" ")[0]) {
                        PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), 0]);    
                    } else {
                        vendordashboardDetails?.past_six_month_order_count?.past_six_month_count.filter((elm) => {
                            PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), elm?.count]);
                        })
                    }
                });
            } else {
                getLastSixMonthOrder.filter((data_, index) => {
                    PastSixPamentArrays.push([data_, 0]);
                });
            }
    
            var data = google.visualization.arrayToDataTable(PastSixPamentArrays);
            
            var options = {
                chart: {
                    // title: 'Company Performance',
                    // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                }
            };
            var chart = new google.charts.Bar(document.getElementById('columnchart_orders'));
            chart.draw(data, google.charts.Bar.convertOptions(options));
        }  

        
        // six months customer list********************************
        const getLastSixMonthCustomer = [];
        var monthNamess = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var todayz = vendordashboardDetails?.past_six_month_customer_count?.past_six_month_count.length > 0 ? new Date(vendordashboardDetails?.past_six_month_customer_count?.past_six_month_count[vendordashboardDetails?.past_six_month_customer_count?.past_six_month_count?.length - 1]?.month) : new Date();
        var dss;
        var monthss;
        for(var i = 6; i >= 0; i -= 1) {
            dss = new Date(todayz.getFullYear(), todayz.getMonth() - i, 1);
            monthss = monthNamess[dss.getMonth()];
            getLastSixMonthCustomer.push(`${monthss} 1, ${moment(dss).format('YYYY')}`);
        };
        function drawChartCustomerChart() {
            const PastSixPamentArrays = [['Year', 'count']];
            if(vendordashboardDetails?.past_six_month_customer_count?.past_six_month_count.length > 0) {
                getLastSixMonthCustomer.filter((data_, index) => {
                    const CurrentBar = vendordashboardDetails?.past_six_month_customer_count?.past_six_month_count[index];
                    if(data_.split(" ")[0] !== moment.utc(CurrentBar?.month).local().format('LL').split(" ")[0]) {
                        PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), 0]);    
                    } else {
                        vendordashboardDetails?.past_six_month_customer_count?.past_six_month_count.filter((elm) => {
                            PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), elm?.count]);
                        })
                    }
                });
            } else {
                getLastSixMonthCustomer.filter((data_, index) => {
                    // const CurrentBar = EmptyMonthArray[index];
                    PastSixPamentArrays.push([data_, 0]);
                });
            }

            var data = google.visualization.arrayToDataTable(PastSixPamentArrays);
            
            var options = {
                chart: {
                    // title: 'Company Performance',
                    // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                }
            };
            var chart = new google.charts.Bar(document.getElementById('columnchart_customer'));
            chart.draw(data, google.charts.Bar.convertOptions(options));
        }
    }, [ UserPar?.roomId ])

    
    return(<div className="dashboardWrapper">
        <Scrollbars 
            style={{ height: "calc(100% - 0px)"}} 
            className="ScrollbarsWrapper"
            renderView={props => <div {...props} className="view"/>}
        >
            <div className="dashBordcountwraps">
                {/* <div className="dashboardcolumn">
                    <div className="iconfor" style={{ backgroundColor: "#fa5a7e", padding: "10px" }}>
                        <Image src={VendorIcon} al="Vendors" />
                    </div>
                    <div className="dashcoldetails">
                        <h5>Vendors</h5>
                        <h1>40</h1>
                    </div>
                </div> */}
                {SelectedVendor?.has_category && <div className="dashboardcolumn" onClick={() => setViewTab("Category")}>
                    <div className="iconfor" style={{ backgroundColor: "#fe947a", padding: "12px 12px" }}>
                        <Image src={CategoryIcon} al="Category" />
                    </div>
                    <div className="dashcoldetails">
                        <h5>Category</h5>
                        <h1>{vendordashboardDetails?.category_count?.total}</h1>
                    </div>
                </div>}
                <div className="dashboardcolumn" onClick={() => setViewTab("Product")}>
                    <div className="iconfor" style={{ backgroundColor: "#3cd757", padding: "10px" }}>
                        <Image src={PorductIcon} al="Product" />
                    </div>
                    <div className="dashcoldetails">
                        <h5>Product</h5>
                        <h1>{vendordashboardDetails?.products_count?.total}</h1>
                    </div>
                </div>
                <div className="dashboardcolumn" onClick={() => setViewTab("Orders")}>
                    <div className="iconfor" style={{ backgroundColor: "#bf83ff", padding: "7px" }}>
                        <Image src={OrderIcon} al="Orders" />
                    </div>
                    <div className="dashcoldetails">
                        <h5>Orders</h5>
                        <h1>{vendordashboardDetails?.order_count?.total}</h1>
                    </div>
                </div>
                {SelectedVendor?.has_customer && (<div className="dashboardcolumn" onClick={() => setViewTab("Customers-list")}>
                    <div className="iconfor" style={{ backgroundColor: "#870fa3", padding: "10px" }}>
                        <Image src={CustomerIcon} al="Customers" />
                    </div>
                    <div className="dashcoldetails">
                        <h5>Customers</h5>
                        <h1>{vendordashboardDetails?.customer_count?.total}</h1>
                    </div>
                </div>)}
            </div>
            <div className="graph_wrapper">
                <div className="graphColumn">
                    <h4>Vendors</h4>
                    <div id="piechart" className="graphcomnset" style={{ width: '900px', height: '500px' }}></div>
                </div>
                <div className="graphColumn">
                    <h4>Six Month Product List</h4>
                    <div id="columnchart_material" className="graphcomnset" style={{ width: '900px', height: '500px' }}></div>
                </div>
                <div className="graphColumn">
                    <h4>Six Month Orders List</h4>
                    <div id="columnchart_orders" className="graphcomnset" style={{ width: '900px', height: '500px' }}></div>
                </div>
                <div className="graphColumn">
                    <h4>Six Month Customer List</h4>
                    <div id="columnchart_customer" className="graphcomnset" style={{ width: '900px', height: '500px' }}></div>
                </div>
            </div>
        </Scrollbars>
    </div>)
};

export default Dashbaord;