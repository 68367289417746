import React from "react";
import Spinner from 'react-bootstrap/Spinner';

const DataLoader = () => {
    return(<div className="loaderBckground">
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        <h4>Please wait data is loading...</h4>
    </div>)
};

export default DataLoader;