/* eslint-disable */
import React from "react";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import { Image } from "react-bootstrap";
import CloseIcon from '../../assets/img/closebutton.png';
import AddIcon from "../../assets/img/plus.png";

const DataTableFilter = (props) => {
    const { filterType, userSearch, ButtonSet, setUserSearch, userType, userLimit, setUserLimit, ClearSearchFiled, SelectUserTypeFnct } = props;

    return(<div className="card-header-New">
        {filterType !== "Orders" && (<div className="searchBoxwithbtn">
            <input type="text" className="form-control" value={userSearch} onKeyDown={(e) => EmptySpaceFieldValid(e)} onChange={(e) => setUserSearch(e.target.value)} placeholder={`Search ${filterType} ...`}/>
            {userSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                <path d="M21 21l-6 -6"></path>
            </svg>) : (<img src={CloseIcon} className="icon searchclear" onClick={() => ClearSearchFiled()} alt="close" />)}
        </div>)}
        <div className="card-header-right">
            {(filterType !== "Vendor" && filterType !== "Vendor Customer" && filterType !== "Orders") && (<select className="form-control activestatusselet" value={userType} onChange={(e) => SelectUserTypeFnct(e)}>
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
            </select>)}
            <select className="form-control userLimitselect" value={userLimit} onChange={(e) => setUserLimit(e.target.value)}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="80">80</option>
                <option value="90">90</option>
                <option value="100">100</option>
            </select>
            {ButtonSet && (<button className="btn head_cmn_btn" onClick={ButtonSet?.function}><Image src={AddIcon} alt="add icon" />{ButtonSet?.buttontitle}</button>)}
        </div>
    </div>)
}

export default DataTableFilter;