/* eslint-disable */
import React, { useState, useEffect } from "react";
import DataTableFilter from "../../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import CommonPagination from "../../Common/CommonPagination";

const Category = (props) => {
    const { wsSend_request, UserPar, websocket } = props;
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { categorylist } = useSelector((state) => state.vendorReducers);
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        getProductCategory();
    }, [ userLimit, userType, currentPage, UserPar?.roomId, userSearch ]);

    // get realtime data
    const getProductCategory = () => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_category_list", 
            "request" :  { 
                "vendor_id" : UserPar?.roomId,
                "filter" : userType,
                "is_pagination":true, 
                "limit" : parseInt(userLimit), 
                "page_no": currentPage,
                "search": userSearch
            }
        };
        wsSend_request(websocket, param);
    };

    return(<>
        <DataTableFilter 
            filterType={"Category"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
        />
        <div className="tableContent" style={{ height: "calc(100% - 65px)" }}>
            <Scrollbars 
                style={{ height: categorylist?.pagination?.length > 10 ? "calc(100% - 0px)" : "calc(100% - 0px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                                <th>Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categorylist && categorylist?.list?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                    <tr>
                                        <td>{user?.name}</td>
                                        <td>{moment(user.created_at).format('LL')}</td>
                                        <td>{moment(user.update_at).format('LL')}</td>
                                        <td><Badge bg={user?.is_active ? "success" : "danger"}>{user?.is_active?"Yes":"No"}</Badge></td>
                                    </tr>
                                </React.Fragment>)
                            })}
                            {categorylist && categorylist?.list?.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Category Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
        </div>
        {categorylist?.pagination?.total_records > 10 && (<CommonPagination 
            currentPage={currentPage}
            paginationLength={categorylist?.pagination?.length}
            currentFunction={currentFunction}
        />)}
    </>)
}

export default Category;