import React, { useState } from "react";
import { Button } from "react-bootstrap";
import InputField from "../Common/InputField";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Link, useNavigate } from "react-router-dom";
import { DASHBOARD_URL } from "../Shared/constant";
import LoginAPI from "../../Api/LoginAPI";
import { useDispatch } from "react-redux";
import { getSelfDetails, getAccessToken, checkLogin, getUserId } from "../../redux/actions/adminActions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FORGOT_PASSWORD_URL } from "../Shared/constant";

const Login = () => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ inputValue, setInputValue ] = useState({ Email : "", Password: ""  });
    const { Email, Password } = inputValue;
    const [ showpassword, setshowpassword ] = useState(false);

    // Regex
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // login function
    const Login = async () => {
        if(inputValue?.Email !== "" && inputValue?.Password !== "") {
            if(EmailRegex.test(inputValue?.Email) === true && PasswordRegex.test(inputValue?.Password) === true) {
                const jsonData = JSON.stringify({ email: inputValue?.Email, password: inputValue?.Password, device_type: "web" });
                const response = await LoginAPI(jsonData);
                if(response?.status === 200){
                    setTimeout(() => {
                        toast.success(response.msg);
                        Navigate(DASHBOARD_URL);
                        Dispatch(getSelfDetails(response?.data?.user));
                        Dispatch(getUserId(response?.data?.user?.id));
                        Dispatch(getAccessToken(response?.data?.access_token));
                        Dispatch(checkLogin("yes"));
                        clearForm();
                    }, 100);
                } else {
                    toast.error(response.error);
                }
            } else {
                CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
                CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
            }
        } else {
            CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
            CheckValid(inputValue?.Password, {type: 'password', error: setErrorPassword });
        }
    };

    // show password
    const ShowHidePassword = () => {
        setshowpassword(!showpassword);
    };

    // clear form
    const clearForm = () => {
        setInputValue({...inputValue, Email : "", Password: ""});
        setErrorEmail("");
        setErrorPassword("");
    };

    return(<section className="login_wrap">
        <div className="login_form">
            <div className="login_Header">
                <h1>POS Admin Panel</h1>
            </div>
            <div className="login_form_main">
                <InputField
                    type="text"
                    value={Email}
                    className={errorEmail !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter email"
                    label="Email"
                    name="Email"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorEmail}
                />
                <InputField
                    type={showpassword ? "text" : "password"}
                    value={Password}
                    className={errorPassword !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter password"
                    label="Password"
                    name="Password"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'password', error: setErrorPassword})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorPassword}
                    onClick={ShowHidePassword}
                    showpassword={showpassword}
                />
                <div className="form-group">
                    <Link to={FORGOT_PASSWORD_URL} className="forgot_password"><i className="bi bi-lock"></i>Forgot Password</Link>
                </div>
                <div className="form-group-btn">
                    <Button className="cmn_bigbtn" onClick={() => Login()}>Login</Button>
                </div>
            </div>
        </div>
    </section>)
};

export default Login;