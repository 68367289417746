/* eslint-disable */
import React, { useState, useEffect } from "react";
import DataTableFilter from "../../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Badge } from "react-bootstrap";
import moment from "moment";
import CommonPagination from "../../Common/CommonPagination";
import { useSelector } from "react-redux";

const Orders = (props) => {
    const { wsSend_request, UserPar, websocket } = props;
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { orderlist, vendorlistidwise, customerlist } = useSelector((state) => state.vendorReducers);

    useEffect(() => {
        getOrderDetails();
    }, [ userLimit, currentPage, UserPar?.roomId ]);

    // get realtime data
    const getOrderDetails = () => {
        let param = { 
            "transmit": "broadcast", 
            "url": "order_list", 
            "request" :  { 
                "vendor_id" : UserPar?.roomId,
                "is_pagination":true, 
                "limit" : parseInt(userLimit), 
                "page_no": currentPage,
            }
        };
        wsSend_request(websocket, param);
    };

    // get customer name
    const GetCustomerName = (id) => {
        const CustomerNames = customerlist?.list.filter((name) => name?.id === id)[0]?.user_name
        return CustomerNames === null ? "-" : CustomerNames;
    };

    return(<>
        <DataTableFilter 
            filterType={"Orders"}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
        />
        <div className="tableContent" style={{ height: "calc(100% - 65px)" }}>
            <Scrollbars 
                style={{ height: orderlist?.pagination?.total_records > 10 ? "calc(100% - 0px)" : "calc(100% - 0px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Customer</th>
                                <th>Amount</th>
                                <th>Discount</th>
                                <th>Ordered At</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderlist && orderlist?.list?.map((user, index) => {
                                return(<React.Fragment key={index}>
                                    <tr>
                                        <td>{vendorlistidwise[user?.vendor_id] }</td>
                                        <td>{user?.customer_name === null ? GetCustomerName(user?.customer_id) : user?.customer_name}</td>
                                        <td>${user?.total_amount}</td>
                                        <td><div className="discountwrps">{user?.discount}%</div></td>
                                        <td>{moment(user.ordered_at).format('LL')}</td>
                                        <td>
                                            <Badge bg={user?.is_paid ? "success" : "danger"}>{user?.is_paid?"Paid":"Pending"}</Badge>
                                        </td>
                                    </tr>
                                </React.Fragment>)
                            })}
                            {orderlist && orderlist?.list?.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>Orders Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
        </div>
        {orderlist?.pagination?.total_records > 10 && (<CommonPagination 
            currentPage={currentPage}
            paginationLength={orderlist?.pagination?.length}
            currentFunction={currentFunction}
        />)}
    </>)
}

export default Orders;