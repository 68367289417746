/* eslint-disable react-hooks/rules-of-hooks */
const wsSend_request = (websocket, params)=>{
    if(websocket.send!==null && websocket.readyState === websocket.OPEN ){
        if(params?.request !== undefined){
            setTimeout(() => {
                websocket?.send(JSON.stringify(params))
            }, 200);
        } else {
            websocket && websocket?.send(JSON.stringify(params))
        };
    }else{
        setTimeout(()=>{
            wsSend_request(websocket, params)
        },1500)
    };
};
export default wsSend_request;