export const ActionTypes = {
    GET_VENDOR_LIST: "GET_VENDOR_LIST",
    ADD_VENDOR: "ADD_VENDOR",
    UPDATE_VENDOR: "UPDATE_VENDOR",
    GET_VENDOR_CUSTOMER_LIST: "GET_VENDOR_CUSTOMER_LIST",
    GET_VENDOR_PRODUCT_LIST: "GET_VENDOR_PRODUCT_LIST",
    GET_VENDOR_CATEGORY_LIST: "GET_VENDOR_CATEGORY_LIST",
    GET_VENDOR_ORDER_LIST: "GET_VENDOR_ORDER_LIST",
    DELETE_VENDOR_LIST: "DELETE_VENDOR_LIST",
    GET_VENDOR_DASHBOARD_COUNT: "GET_VENDOR_DASHBOARD_COUNT"
};

export const getVendorList = (vendorlist) => {
    return {
        type: ActionTypes.GET_VENDOR_LIST,
        payload: vendorlist,
    }
}

export const getVendorDashBoardCount = (dashboard) => {
    return {
        type: ActionTypes.GET_VENDOR_DASHBOARD_COUNT,
        payload: dashboard,
    }
}

export const addNewVendor = (vendor) => {
    return {
        type: ActionTypes.ADD_VENDOR,
        payload: vendor,
    }
};

export const deleteVendorList = (vendor) => {
    return {
        type: ActionTypes.DELETE_VENDOR_LIST,
        payload: vendor,
    }
};

export const UpdateVendor = (vendor) => {
    return {
        type: ActionTypes.UPDATE_VENDOR,
        payload: vendor,
    }
};

export const getVendorCustomerList = (customerlist) => {
    return {
        type: ActionTypes.GET_VENDOR_CUSTOMER_LIST,
        payload: customerlist,
    }
};

export const getVendorProductList = (productlist) => {
    return {
        type: ActionTypes.GET_VENDOR_PRODUCT_LIST,
        payload: productlist,
    }
}

export const getVendorCategoryList = (catgorylist) => {
    return {
        type: ActionTypes.GET_VENDOR_CATEGORY_LIST,
        payload: catgorylist,
    }
}

export const getVendorOrderList = (orderlist) => {
    return {
        type: ActionTypes.GET_VENDOR_ORDER_LIST,
        payload: orderlist,
    }
}