/* eslint-disable */
import React, { useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import UpdateVendorAPI from "../../Api/UpdateVendorAPI";
import DeleteVendorAPI from "../../Api/DeleteVendorAPI"; 
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UpdateVendor, deleteVendorList } from "../../redux/actions/vendorActions";
import BtnLoader from "./BtnLoader";
import CommonLogout from "./CommonLogout";

const AllowPermissionModal = (props) => {
    const { showPermisson, setShowPermisson } = props;
    const [ loader, setLoader ] = useState(false);
    const { accessToken } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();

    // close modal
    const handleClose = () => {
        setShowPermisson({...showPermisson, open: false});
    };

    const AllowPermsBtn = async () => {
        setLoader(true);
        // uddate vendor 
        if(showPermisson?.API === "update-vendor") { 
            const jsonData = {
                user_id: showPermisson.Data.id
            }
            jsonData[showPermisson.modalType.type] = showPermisson.modalType.action ? 0 : 1;       
            const response = await UpdateVendorAPI(jsonData, accessToken);
            if(response?.status === 200){
                setTimeout(() => {
                    setLoader(false);
                    Dispatch(UpdateVendor(response?.data));
                    handleClose();
                    toast.success(response.msg);
                }, 100);
            } else if(response?.status === 403){
                CommonLogout(accessToken, Dispatch);
            } else {
                setLoader(false);
                toast.error(response.error);
            }
        } else if(showPermisson?.API === "delete-vendor") {
            const response = await DeleteVendorAPI(accessToken, showPermisson?.Data?.id);
            if(response?.status === 200){
                setTimeout(() => {
                    setLoader(false);
                    Dispatch(deleteVendorList(response?.data));
                    handleClose();
                    toast.success(response.msg);
                }, 100);
            } else if(response?.status === 403){
                CommonLogout(accessToken, Dispatch);
            } else {
                setLoader(false);
                toast.error(response.error);
            }
        };
    };

    return(<Modal className="AllowPermissionModal" show={showPermisson?.open} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title style={{ width: "100%"}}>{showPermisson?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p dangerouslySetInnerHTML={{__html: showPermisson?.description}}></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => showPermisson?.modalType?.type === "logout" ? CommonLogout(accessToken, Dispatch) : AllowPermsBtn()}>
            {loader && (<BtnLoader />)}&nbsp;{showPermisson?.button}
          </Button>
        </Modal.Footer>
    </Modal>)
}

export default AllowPermissionModal;