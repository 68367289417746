export const LOGIN_URL = "/login";
export const FORGOT_PASSWORD_URL = "/forgot-password";
export const RESET_PASSWORD_URL = "/reset-password";
export const DASHBOARD_URL = "/dashboard";
export const PROFILE_URL = "/update-profile";
export const USER_MANAGEMENT_URL = "/user-management";
export const VENDOR_MANAGEMENT_URL = "/vendor-management";
export const ADD_NEW_VENDOR_URL = "/add-vendor";
export const EDIT_VENDOR_URL = "/edit-vendor";
export const VIEW_VENDOR_URL = "/view-vendor";
export const DEVICES_LIST_URL = "/devices-list";

// Api login urls
export const API_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN_API_URL = API_URL + "admin/login";
export const FORGOT_PASSWORD_API_URL = API_URL + "admin/forgot-password";
export const CREATE_PASSWORD_API_URL = API_URL + "admin/create-password";
export const LOGOUT_API_URL = API_URL + "user/logout";
export const GET_VENDOR_LIST_API_URL = API_URL + "admin/vendor/list";
export const ADD_VENDOR_API_URL = API_URL + "admin/vendor/add";
export const UPDATE_VENDOR_API_URL = API_URL + "admin/vendor/update";
export const GET_VENDOR_DETAILS_API_URL = API_URL + "admin/vendor/get";
export const DELETE_VENDOR_API_URL = API_URL + "admin/vendor/delete";
export const ADMIN_DASHBOARD_API_URL = API_URL + "admin/dashboard";
export const VENDOR_DASHBOARD_API_URL = API_URL + "user/dashboard";