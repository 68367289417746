/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { WebSocketContext } from "../../App";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import { Scrollbars } from 'react-custom-scrollbars-2';
import InputField from "../Common/InputField";
import { useSelector } from "react-redux";
import BtnLoader from "../Common/BtnLoader";
import DataLoader from "../Common/DataLoader";
import wsSend_request from "../../Api/ws/ws_request";
import { DASHBOARD_URL } from "../Shared/constant";
import { useNavigate } from "react-router";

const Profile = () => {
    const Navigate = useNavigate();
    const { websocket } = useContext(WebSocketContext);
    const { selfInfo } = useSelector((state) => state.adminReducers);
    const [ inputValue, setInputValue ] = useState({ 
        Fname: "", Lname: "", Business_name: "", Address: "", Old_password: "", Password: ""
    });
    const { Fname, Lname, Business_name, Address, Old_password, Password } = inputValue;
    const [ showpassword, setshowpassword ] = useState(false);
    const [ showOldpassword, setshowOldpassword ] = useState(false);

    // error message
    const [ errorFname, setErrorFname ] = useState("");
    const [ errorLname, setErrorLname ] = useState("");
    const [ errorBusiness_name, setErrorBusiness_name ] = useState("");
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ errorOldPassword, setErrorOldPassword ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");

    const [ loader, setLoader ] = useState(false);
    const [ APILoader, setAPILoader ] = useState(false);
    const [ ChangePasswordState, setChangePasswordState ] = useState(false);

    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line

    useEffect(() => {
        if(ChangePasswordState === true) {
            const MainWrapsId = document.getElementById("chnagepasswordblog");
            MainWrapsId.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [ ChangePasswordState ])

    
    useEffect(() => {
        if(selfInfo) {
            setInputValue({
                ...inputValue, 
                Fname: selfInfo?.first_name, 
                Lname: selfInfo?.last_name, 
                Business_name: selfInfo?.business_name === null ? "" : selfInfo?.business_name, 
                Address: selfInfo?.address === null ? "" : selfInfo?.address
            });
        };
    }, [ selfInfo ]);

    // Textfield common onchange function
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // show password
    const ShowHidePassword = () => {
        setshowpassword(!showpassword);
    };

    // show password
    const ShowOldHidePassword = () => {
        setshowOldpassword(!showOldpassword);
    };

    // edit profile
    const EditProfile = () => {
        if(inputValue?.Fname !== "" && inputValue?.Lname !== "" && inputValue?.Business_name !== "" && inputValue?.Address !== "") {
            let param = { 
                "transmit": "broadcast", 
                "url": "profile_update", 
                "request" :  { 
                    "first_name" : inputValue?.Fname, 
                    "last_name" : inputValue?.Lname, 
                    "business_name" : inputValue?.Business_name, 
                    "address" : inputValue?.Address
                }
            };
            wsSend_request(websocket, param);
            setInputValue({
                ...inputValue, 
                Fname: "",
                Lname: "",
                Business_name: "",
                Address: ""
            });
            setTimeout(() => Navigate(DASHBOARD_URL), 200);
        } else {
            CheckValid(inputValue?.Fname, {type: 'Fname', error: setErrorFname });
            CheckValid(inputValue?.Lname, {type: 'Lname', error: setErrorLname });
            CheckValid(inputValue?.Business_name, {type: 'Business_name', error: setErrorBusiness_name });
            CheckValid(inputValue?.Address, {type: 'Address', error: setErrorAddress });
        };
    };

    // password changes
    const ChangePasswordFnct = () => {
        if(inputValue?.Old_password !== "" && inputValue?.Password !== "") {
            if(PasswordRegex.test(inputValue?.Old_password) === true && PasswordRegex.test(inputValue?.Password) === true) {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "change_password", 
                    "request" :  { 
                        "old_password" : inputValue?.Old_password, 
                        "new_password" : inputValue?.Password
                    }
                };
                wsSend_request(websocket, param);
                setInputValue({
                    ...inputValue, 
                    Old_password: "",
                    Password: ""
                });
            } else {
                CheckValid(inputValue?.Old_password, {type: 'oldpassword', error: setErrorOldPassword });
                CheckValid(inputValue?.Password, {type: 'newpassword', error: setErrorPassword });
            }
        } else {
            CheckValid(inputValue?.Old_password, {type: 'oldpassword', error: setErrorOldPassword });
            CheckValid(inputValue?.Password, {type: 'newpassword', error: setErrorPassword });
        }
    };

    return(<section className="profile_section">
        <div className="headerform">
            <button type="button" className="btn btn-link add_backbtn" onClick={() => Navigate(DASHBOARD_URL)}>
                <i className="bi bi-arrow-90deg-left"></i>Back
            </button>
        </div>
        <Scrollbars 
            style={{ height: "calc(100% - 53px)" }} 
            className="ScrollbarsWrapper"
            renderView={props => <div {...props} className="view"/>}
        >
            <div className="form-sections">
                <div className="row">
                    <div className="col-md-6">
                        <InputField
                            type="text"
                            value={Fname}
                            className={errorFname !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter First Name"
                            label="First Name"
                            name="Fname"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'Fname', error: setErrorFname})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorFname}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputField
                            type="text"
                            value={Lname}
                            className={errorLname !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter Last Name"
                            label="Last Name"
                            name="Lname"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'Lname', error: setErrorLname})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorLname}
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Email</label>
                            <div className="inputText">
                                {selfInfo?.email === null ? "-" : selfInfo?.email}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <InputField
                            type="text"
                            value={Business_name}
                            className={errorBusiness_name !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter Business Name"
                            label="Business Name"
                            name="Business_name"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'Business_name', error: setErrorBusiness_name})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorBusiness_name}
                        />
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Mobile No</label>
                            <div className="inputText">
                                +{selfInfo?.phone_code === null ? "91" : "-"} &nbsp; {selfInfo?.phone === null ? "-" : selfInfo?.phone}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <InputField
                            type="text"
                            value={Address}
                            className={errorAddress !== "" ? "form-control error-form" : "form-control"}
                            placeholder="Enter Address"
                            label="Address"
                            name="Address"
                            onChange={handleChange}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'Address', error: setErrorAddress})}
                            onKeyDown={EmptySpaceFieldValid}
                            errormessage={errorAddress}
                        />
                    </div>
                    <div className="col-md-12">
                        <div className="form-group-btn" style={{ textAlign: "right" }}>
                            <button type="button" className="btn cmnformbtnlink" onClick={() => setChangePasswordState(!ChangePasswordState)}>
                                <i className="bi bi-lock-fill"></i> Change Password
                            </button>
                            <button type="button" className="btn cmnformbtn" onClick={() => EditProfile()}>
                                {loader && (<BtnLoader />)}&nbsp;Save
                            </button>
                        </div>
                    </div>
                </div>
                {loader && (<DataLoader />)}
            </div>

            {/* change password */}
            {ChangePasswordState && (<div className="form-sections form-sections-reset-password" id="chnagepasswordblog">
                <h4>Change Password</h4>
                <InputField
                    type={showOldpassword ? "text" : "password"}
                    value={Old_password}
                    className={errorOldPassword !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter Old password"
                    label="Enter Old Password"
                    name="Old_password"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'oldpassword', error: setErrorOldPassword})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorOldPassword}
                    onClick={ShowOldHidePassword}
                    showOldpassword={showOldpassword}
                />
                <InputField
                    type={showpassword ? "text" : "password"}
                    value={Password}
                    className={errorPassword !== "" ? "form-control error-form" : "form-control"}
                    placeholder="Enter New password"
                    label="Enter New Password"
                    name="Password"
                    onChange={handleChange}
                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'newpassword', error: setErrorPassword})}
                    onKeyDown={EmptySpaceFieldValid}
                    errormessage={errorPassword}
                    onClick={ShowHidePassword}
                    showpassword={showpassword}
                />
                <div className="col-md-12">
                    <div className="form-group-btn" style={{ textAlign: "right" }}>
                        <button type="button" className="btn cmnformbtn" onClick={() => ChangePasswordFnct()}>
                            {loader && (<BtnLoader />)}&nbsp;Change
                        </button>
                        <button type="button" className="btn cmnformbtnlink" onClick={() => setChangePasswordState(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>)}
        </Scrollbars>
    </section>)
}

export default Profile;