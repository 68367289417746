/* eslint-disable */
import React, { useEffect, useState } from "react";
import CheckValid from "../Common/CheckValid";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import InputField from "../Common/InputField";
import CommonToggle from "../Common/CommonToggle";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { VENDOR_MANAGEMENT_URL } from "../Shared/constant";
import AddVendorAPI from "../../Api/AddVendorAPI";
import UpdateVendorAPI from "../../Api/UpdateVendorAPI";
import GetVendorDetailsAPI from "../../Api/GetVendorDetailsAPI";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { addNewVendor, UpdateVendor } from "../../redux/actions/vendorActions";
import { Scrollbars } from 'react-custom-scrollbars-2';
import BtnLoader from "../Common/BtnLoader";
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
import CommonLogout from "../Common/CommonLogout";
import DataLoader from "../Common/DataLoader";

const AddEditVendor = () => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const Location = useLocation();
    const UserPar = useParams();
    const { accessToken } = useSelector((state) => state.adminReducers);
    const [ inputValue, setInputValue ] = useState({ 
        Fname: "", Lname: "", Email : "", Business_name: "", Address: "" 
    });
    const { Fname, Lname, Email, Business_name, Address } = inputValue;
    const [ customer, setCustomer ] = useState(false);
    const [ discount, setDiscount ] = useState(false);
    const [ category, setCategory ] = useState(false);
    const [ MobileNo, setMobileNo ] = useState({
        phone_code: "91",
        phone: ""
    })

    const [ getCurrentVendorId, setGetCurrentVendorId ] = useState("");

    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorFname, setErrorFname ] = useState("");
    const [ errorLname, setErrorLname ] = useState("");
    const [ errorBusiness_name, setErrorBusiness_name ] = useState("");
    const [ errorAddress, setErrorAddress ] = useState("");
    const [ errorPhone, setErrorPhone ] = useState("");

    const [ loader, setLoader ] = useState(false);
    const [ APILoader, setAPILoader ] = useState(false);
    
    // Regex
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line

    // Textfield common onchange function
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setInputValue((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // get data for edit vendor
    useEffect(() => {
        if(Object.keys(UserPar).length > 0) {
            GetVendorDetails();
        }
    }, [ UserPar?.roomId ]);

    // get vendor details api
    const GetVendorDetails = async () => {
        setAPILoader(true);
        const response = await GetVendorDetailsAPI(accessToken, UserPar?.roomId);
        if(response?.status === 200){
            setTimeout(() => {
                setAPILoader(false);
                setInputValue({...inputValue, Fname: response?.data?.first_name, Lname: response?.data?.last_name, Email : response?.data?.email, Business_name: response?.data?.business_name, Address: response?.data?.address })    
                setCustomer(response?.data?.has_customer);
                setDiscount(response?.data?.has_discount);
                setCategory(response?.data?.has_category);
                setGetCurrentVendorId(response?.data?.id);
                setMobileNo({...MobileNo, phone_code: response?.data?.phone_code, phone: response?.data?.phone})
            }, 200);
        } else if(response?.status === 403){
            setAPILoader(false);
            CommonLogout(accessToken, Dispatch);
        } else {
            setAPILoader(false);
            toast.error(response.error);
            setLoader(false);
        };
    };

    // add vendor
    const AddVendorFnct = async () => {
        if(inputValue?.Fname !== "" && inputValue?.Lname !== "" && inputValue?.Email !== "" && inputValue?.Business_name !== "" && inputValue?.Address !== "") {
            if(EmailRegex.test(inputValue?.Email) === true) {
                setLoader(true);
                if(Location?.pathname.split("/")[1] === "edit-vendor") {
                    const jsonData = JSON.stringify({ 
                        first_name: inputValue?.Fname,
                        last_name: inputValue?.Lname,
                        phone_code: MobileNo?.phone_code,
                        phone: MobileNo?.phone,
                        email: inputValue?.Email, 
                        business_name: inputValue?.Business_name,
                        address: inputValue?.Address,
                        has_customer: customer ? 1 : 0,
                        has_discount: discount ? 1 : 0,
                        has_category: category ? 1 : 0,
                        user_id: getCurrentVendorId,
                    });
                    const response = await UpdateVendorAPI(jsonData, accessToken);
                    if(response?.status === 200){
                        setTimeout(() => {
                            setLoader(false);
                            toast.success(response.msg);
                            Dispatch(UpdateVendor(response?.data));
                            Navigate(VENDOR_MANAGEMENT_URL);
                            clearForm();
                        }, 100);
                    } else if(response?.status === 403){
                        CommonLogout(accessToken, Dispatch);
                    } else {
                        toast.error(response.error);
                        setLoader(false);
                    }
                } else {
                    const jsonData = JSON.stringify({ 
                        first_name: inputValue?.Fname,
                        last_name: inputValue?.Lname,
                        phone_code: MobileNo?.phone_code,
                        phone: MobileNo?.phone,
                        email: inputValue?.Email, 
                        business_name: inputValue?.Business_name,
                        address: inputValue?.Address,
                        has_customer: customer,
                        has_discount: discount,
                        has_category: category,
                    });
                    const response = await AddVendorAPI(jsonData, accessToken);
                    if(response?.status === 200){
                        setTimeout(() => {
                            setLoader(false);
                            toast.success(response.msg);
                            Dispatch(addNewVendor(response?.data));
                            Navigate(VENDOR_MANAGEMENT_URL);
                            clearForm();
                        }, 100);
                    } else if(response?.status === 403){
                        CommonLogout(accessToken, Dispatch);
                    } else {
                        toast.error(response.error);
                        setLoader(false);
                    }
                }
                
            } else {
                CheckValid(inputValue?.Fname, {type: 'Fname', error: setErrorFname });
                CheckValid(inputValue?.Lname, {type: 'Lname', error: setErrorLname });
                CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
                CheckValid(inputValue?.Business_name, {type: 'Business_name', error: setErrorBusiness_name });
                CheckValid(inputValue?.Address, {type: 'Address', error: setErrorAddress });
                CheckValid(MobileNo.phone, {type: 'phone', error: setErrorPhone })
            }
        } else {
            CheckValid(inputValue?.Fname, {type: 'Fname', error: setErrorFname });
            CheckValid(inputValue?.Lname, {type: 'Lname', error: setErrorLname });
            CheckValid(inputValue?.Email, {type: 'email', error: setErrorEmail });
            CheckValid(inputValue?.Business_name, {type: 'Business_name', error: setErrorBusiness_name });
            CheckValid(inputValue?.Address, {type: 'Address', error: setErrorAddress });
            CheckValid(MobileNo.phone, {type: 'phone', error: setErrorPhone })
        };
    };

    // clear form
    const clearForm = () => {
        setInputValue({...inputValue, Email : "", Lname: "", Email: "", Business_name: "", Address: ""});
        setErrorFname("");
        setErrorLname("");
        setErrorEmail("");
        setErrorBusiness_name("");
        setErrorAddress("");
        setCustomer(false); 
        setDiscount(false); 
        setCategory(false);
        setMobileNo({...MobileNo,  phone: "", phone_code: "91"});
    };

    return(<>
    <div className="headerform">
        <button type="button" className="btn btn-link add_backbtn" onClick={() => Navigate(VENDOR_MANAGEMENT_URL)}>
            <i className="bi bi-arrow-90deg-left"></i>Back
        </button>
    </div>
    <Scrollbars 
        style={{ height: "calc(100% - 53px)" }} 
        className="ScrollbarsWrapper"
        renderView={props => <div {...props} className="view"/>}
    >
        <div className="form-sections">
       
            <div className="row">
                <div className="col-md-6">
                    <InputField
                        type="text"
                        value={Fname}
                        className={errorFname !== "" ? "form-control error-form" : "form-control"}
                        placeholder="Enter First Name"
                        label="First Name"
                        name="Fname"
                        onChange={handleChange}
                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'Fname', error: setErrorFname})}
                        onKeyDown={EmptySpaceFieldValid}
                        errormessage={errorFname}
                    />
                </div>
                <div className="col-md-6">
                    <InputField
                        type="text"
                        value={Lname}
                        className={errorLname !== "" ? "form-control error-form" : "form-control"}
                        placeholder="Enter Last Name"
                        label="Last Name"
                        name="Lname"
                        onChange={handleChange}
                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'Lname', error: setErrorLname})}
                        onKeyDown={EmptySpaceFieldValid}
                        errormessage={errorLname}
                    />
                </div>
                <div className="col-md-6">
                    <InputField
                        type="text"
                        value={Email}
                        className={errorEmail !== "" ? "form-control error-form" : "form-control"}
                        placeholder="Enter Email"
                        label="Email"
                        name="Email"
                        onChange={handleChange}
                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail})}
                        onKeyDown={EmptySpaceFieldValid}
                        errormessage={errorEmail}
                    />
                </div>
                <div className="col-md-6">
                    <InputField
                        type="text"
                        value={Business_name}
                        className={errorBusiness_name !== "" ? "form-control error-form" : "form-control"}
                        placeholder="Enter Business Name"
                        label="Business Name"
                        name="Business_name"
                        onChange={handleChange}
                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'Business_name', error: setErrorBusiness_name})}
                        onKeyDown={EmptySpaceFieldValid}
                        errormessage={errorBusiness_name}
                    />
                </div>
                <div className="col-md-6">
                    <CountrySelectInput 
                        phone={MobileNo} 
                        setPhone={setMobileNo} 
                        CheckValid={CheckValid} 
                        setErrorPhone={setErrorPhone} 
                        errorPhone={errorPhone}
                    />
                </div>
                <div className="col-md-6" style={{ display: "flex" }}>
                    <CommonToggle 
                        name={"Customer"}
                        valueToggle={customer}
                        setValueToggle={setCustomer}
                    />
                    <CommonToggle 
                        name={"Discount"}
                        valueToggle={discount}
                        setValueToggle={setDiscount}
                    />
                    <CommonToggle 
                        name={"Category"}
                        valueToggle={category}
                        setValueToggle={setCategory}
                    />
                </div>
                <div className="col-md-12">
                    <InputField
                        type="text"
                        value={Address}
                        className={errorAddress !== "" ? "form-control error-form" : "form-control"}
                        placeholder="Enter Address"
                        label="Address"
                        name="Address"
                        onChange={handleChange}
                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'Address', error: setErrorAddress})}
                        onKeyDown={EmptySpaceFieldValid}
                        errormessage={errorAddress}
                    />
                </div>
                <div className="col-md-12">
                    <div className="form-group-btn">
                        <button type="button" className="btn cmnformbtn" onClick={() => AddVendorFnct()}>
                        {loader && (<BtnLoader />)}&nbsp;Add</button>
                    </div>
                </div>
            </div>
            {APILoader && (<DataLoader />)}
        </div>
    </Scrollbars>
    </>)
}

export default AddEditVendor;