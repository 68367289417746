import { getVendorCustomerList, getVendorCategoryList, getVendorProductList, getVendorOrderList } from "../../redux/actions/vendorActions";
import { editSelfDetails, getSessionLists, deleteSessionList } from "../../redux/actions/adminActions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ws_response = ({evt, ws}) =>{
    return(async (dispatch, getState) => {
        const ws_onmessage = JSON.parse(evt.data);
        console.log("ws_onmessage=====>", ws_onmessage);
        // Sw notification setup
        switch(ws_onmessage.url){
            case "customer_list":
                dispatch(getVendorCustomerList(ws_onmessage?.response));
                break;
            case "paginated_product_list":
                dispatch(getVendorProductList(ws_onmessage?.response));
                break;
            case "product_category_list":
                dispatch(getVendorCategoryList(ws_onmessage?.response));
                break;
            case "order_list":
                dispatch(getVendorOrderList(ws_onmessage?.response));
                break;
            case "profile_update":
                dispatch(editSelfDetails(ws_onmessage?.response));
                break;
            case "change_password":
                if(ws_onmessage?.response) {
                    ws_onmessage?.response?.status ? toast.success("Your password has been changed.") : toast.error("Please enter correct old password.");
                }
                break;
            case "session_list":
                dispatch(getSessionLists(ws_onmessage?.response));
                break;
            case "session_logout":
                dispatch(deleteSessionList(ws_onmessage?.response));
                break;
            default:
                return;
        }
    })
};
        
