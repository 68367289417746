/* eslint-disable */
import React, { useState, useEffect } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ADD_NEW_VENDOR_URL, EDIT_VENDOR_URL, VIEW_VENDOR_URL } from "../Shared/constant";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Image, Collapse } from "react-bootstrap";
import MoreIcon from '../../assets/img/more_options.png';
import moment from "moment";
import CommonToggle from "../Common/CommonToggle";
import AllowPermissionModal from "../Common/AllowPermissionModal";
import GetVendorListAPI from "../../Api/GetVendorListAPI";
import { getVendorList } from "../../redux/actions/vendorActions";
import CommonPagination from "../Common/CommonPagination";

const VendorManagement = () => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { accessToken, user_id } = useSelector((state) => state.adminReducers);
    const { vendorlist } = useSelector((state) => state.vendorReducers);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showPermisson, setShowPermisson ] = useState({
        open: false,
        title: "",
        modalType: {
            type: "",
            action: "",
        },
        button: "",
        Data: {}
    });
    const [ paginationLength, setPaginationLength ] = useState({});

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchData();
    }, [ userSearch, userType, userLimit, currentPage ]);

    // // Get data from api
    const fetchData = async () => {
        const userList = await GetVendorListAPI(accessToken, userType , userLimit, currentPage, userSearch === "" ? 0 : userSearch);
        if(userList?.status === 200) {
            dispatch(getVendorList(userList.data));
            setPaginationLength(userList.pagination);
        } else if(userList?.status === 403) {
            CommonLogout();
            dispatch(getVendorList([]));
        } else {
            dispatch(getVendorList([]));
        };
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // add new vendor
    const AddVendorFnct = () => {
        Navigate(ADD_NEW_VENDOR_URL);
    };

    // vendor allow all permission
    const VendorPermissionAllow = (data_, type, booleancheck ) => {
        setShowPermisson({...showPermisson, 
            title: "Are you sure?", 
            open: !showPermisson.open, 
            modalType: {
                type: type,
                action: booleancheck,
            }, 
            button: "Yes",
            Data: data_,
            API: "update-vendor",
            description: `Do you really want to ${booleancheck ? "remove " : ""} ${type.split("_")[1]} to <h4>${data_?.email}</h4>`
        });
    };

    // delete vendor function
    const DeleteVendor = (data_) => {
        setShowPermisson({...showPermisson, 
            title: "Are you sure?", 
            open: !showPermisson.open, 
            button: "Yes",
            Data: data_,
            API: "delete-vendor",
            description: `Do you really want to delete <h4>${data_?.email}</h4>`
        });
    };

    return(<>
        <DataTableFilter 
            filterType={"Vendor"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            ClearSearchFiled={ClearSearchFiled}
            SelectUserTypeFnct={SelectUserTypeFnct}
            ButtonSet={{
                buttontitle: "Add Vendor",
                function: AddVendorFnct
            }}
        />
        <div className="tableContent" style={{ height: "calc(100% - 85px)" }}>
            <Scrollbars 
                style={{ height: paginationLength?.total_records > 10 ? "calc(100% - 0px)" : "calc(100% - 0px)" }} 
                className="ScrollbarsWrapper"
                renderView={props => <div {...props} className="view"/>}
            >
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Business name</th>
                                <th>Active</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vendorlist && vendorlist?.map((user, index) => {
                                if(user?.id !== user_id) {
                                    return(<React.Fragment key={index}>
                                        <tr className={activeTableDetails === user?.id ? "active" : ""}>
                                            <td style={{ width: '10px' }}>
                                                <button 
                                                    className={activeTableDetails === user?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                    onClick={() => activeTableDetails === user?.id ? ElseMoreDetails() : ViewMoreDetails(user?.id)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={activeTableDetails === user?.id ? true : false}
                                                >
                                                    <Image src={MoreIcon} alt="more icon" />
                                                </button>
                                            </td>
                                            <td>{user?.first_name}</td>
                                            <td>{user?.last_name}</td>
                                            <td>{user?.email}</td>
                                            <td>
                                                {user?.phone === null ? "-" : "+"+user?.phone_code + " " + user?.phone}
                                            </td>
                                            <td>{user?.business_name}</td>
                                            <td>
                                                <CommonToggle 
                                                    valueToggle={user?.is_active}
                                                    setValueToggle={() => VendorPermissionAllow(user, "is_active", user?.is_active)}
                                                    name={""}
                                                />
                                            </td>
                                            <td>
                                                <button type="button" className="btn actiontblebtn" onClick={() => Navigate(`${VIEW_VENDOR_URL}/${user?.id}`)}>
                                                    <i className="bi bi-eye"></i>
                                                </button>
                                                <button type="button" className="btn actiontblebtn" onClick={() => Navigate(`${EDIT_VENDOR_URL}/${user?.id}`)}>
                                                    <i className="bi bi-pencil-square"></i>
                                                </button>
                                                <button type="button" className="btn actiontblebtn" onClick={() => DeleteVendor(user)}>
                                                    <i className="bi bi-trash-fill" style={{ color: "red" }}></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <Collapse in={activeTableDetails === user?.id ? true : false}>
                                            <tr className="tablecollapsewraps" id={"tablecollpase"+user?.id}>
                                                <td colSpan="8">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <th style={{ width: "20%" }}>Address</th>
                                                                <td>{user?.country === null ? "-" : user?.address}</td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{ width: "20%" }}>Registered At</th>
                                                                <td>{moment(user.created_at).format('LL')}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Admin</th>
                                                                <td>
                                                                    <CommonToggle 
                                                                        valueToggle={user?.is_admin}
                                                                        setValueToggle={() => VendorPermissionAllow(user, "is_admin", user?.is_admin)}
                                                                        name={""}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Vendor</th>
                                                                <td>
                                                                    <CommonToggle 
                                                                        valueToggle={user?.is_vendor}
                                                                        setValueToggle={() => VendorPermissionAllow(user, "is_vendor", user?.is_vendor)}
                                                                        name={""}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Category</th>
                                                                <td>
                                                                    <CommonToggle 
                                                                        valueToggle={user?.has_category}
                                                                        setValueToggle={() => VendorPermissionAllow(user, "has_category", user?.has_category)}
                                                                        name={""}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Customer</th>
                                                                <td>
                                                                    <CommonToggle 
                                                                        valueToggle={user?.has_customer}
                                                                        setValueToggle={() => VendorPermissionAllow(user, "has_customer", user?.has_customer)}
                                                                        name={""}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Discount</th>
                                                                <td>
                                                                    <CommonToggle 
                                                                        valueToggle={user?.has_discount}
                                                                        setValueToggle={() => VendorPermissionAllow(user, "has_discount", user?.has_discount)}
                                                                        name={""}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </Collapse>
                                    </React.Fragment>)
                                }
                            })}
                            {vendorlist && vendorlist.length === 0 && <tr><td style={{ textAlign: "center" }} colSpan={8}>User Not Found !</td></tr>}
                        </tbody>
                    </table>
                </div>
            </Scrollbars>
        </div>
        {/* pagination */}
        {paginationLength?.total_records > 10 && (<CommonPagination 
            currentPage={currentPage}
            paginationLength={paginationLength}
            currentFunction={currentFunction}
        />)}
        {showPermisson?.open && (<AllowPermissionModal showPermisson={showPermisson} setShowPermisson={setShowPermisson} />)}
    </>)
};

export default VendorManagement;