import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";

const AuthLayout = ({children}) => {
    return(<section className="adminmain">
        <Sidebar />
        <div className={"panelwrapper active"}>
            <Header />
            <div className="panelContentwrapper">
                <section className="vendor_sections">
                    {children}
                </section>
            </div>
        </div>
    </section>)
}

export default AuthLayout;