/* eslint-disable */
import React, {createContext, useEffect, useState} from "react";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../src/assets/css/style.css';
import { LOGIN_URL, DASHBOARD_URL, PROFILE_URL, RESET_PASSWORD_URL, FORGOT_PASSWORD_URL, DEVICES_LIST_URL, USER_MANAGEMENT_URL, VENDOR_MANAGEMENT_URL, ADD_NEW_VENDOR_URL, EDIT_VENDOR_URL, VIEW_VENDOR_URL } from './components/Shared/constant';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import AuthLayout from './components/Shared/AuthLayout';
import GuestLayout from './components/Shared/GuestLayout';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import UserManagement from './components/UserManagement';
import VendorManagement from './components/VendorManagement';
import AddEditVendor from './components/VendorManagement/AddEditVendor';
import ViewVendor from './components/VendorManagement/ViewVendor';
import ResetPassword from "./components/ResetPassword";
import { ws_response } from "./Api/ws/ws_response";
import Profile from "./components/Profile";
import Device from "./components/Device";
import ForgotPassword from "./components/ForgotPassword";
import GetVendorListAPI from "./Api/GetVendorListAPI";
import { getVendorList } from "./redux/actions/vendorActions";
export const WebSocketContext = createContext();
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  
function App(props) {
  const { accessToken, is_login } = useSelector((state) => state.adminReducers);
  const Dispatch = useDispatch();
  
  const [ GetWEBSocket, setGetWEBSocket ] = useState("");
  // websocket connections
  useEffect(() => {
      var ws = new WebSocket(`${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`);
      setGetWEBSocket(ws);
      ws.onmessage = function (evt) {
        Dispatch(ws_response({evt:evt, ws:ws }))
        const ws_onmessage = JSON.parse(evt.data);
        if(ws_onmessage?.url === "reconnect"){
          props?.callRender(`${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`, accessToken)
        }
      }
      ws.onclose = async function (evt){
        if(navigator.onLine === false){
          props?.callRender(`${process.env.REACT_APP_SERVICE_URL}/${accessToken}/`, accessToken)
        }
      }
      window.addEventListener('online', () => {
        window.location.reload()
      });
      window.addEventListener("offline",()=>{
        window.location.reload()
      });
      return () => {
        ws.close()
      }
  }, [ accessToken ]);

  useEffect(() => {
      fetchData();
  }, [ accessToken ]);

  // // Get data from api
  const fetchData = async () => {
      const userList = await GetVendorListAPI(accessToken, "all" , 5000, 1, 0);
      if(userList?.status === 200) {
          Dispatch(getVendorList(userList.data));
      } else if(userList?.status === 403) {
          CommonLogout();
          Dispatch(getVendorList([]));
      } else {
          Dispatch(getVendorList([]));
      };
  };

  return (<WebSocketContext.Provider value={{websocket:GetWEBSocket}}>
    <div className="wrapper">
      <BrowserRouter>
          <Routes>
            <Route path="*" element={<Navigate replace to={(accessToken === "" && is_login === "no") ? LOGIN_URL : DASHBOARD_URL}/>} />
            <Route path="/" element={(accessToken === "" && is_login === "no") ? <GuestLayout><Login /></GuestLayout> : <Navigate  replace to={DASHBOARD_URL}/>} >
                <Route path={LOGIN_URL} element={(accessToken === "" && is_login === "no") ? <GuestLayout><Login /></GuestLayout> : <Navigate replace to={DASHBOARD_URL}/>} />
            </Route>
            <Route path={FORGOT_PASSWORD_URL} element={<GuestLayout><ForgotPassword /></GuestLayout>} />
            <Route path={`${RESET_PASSWORD_URL}/:roomId`} element={<ResetPassword />} />
            <Route path={PROFILE_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><Profile /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={DASHBOARD_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><Dashboard /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={USER_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><UserManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={VENDOR_MANAGEMENT_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><VendorManagement /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />              
            <Route path={ADD_NEW_VENDOR_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddEditVendor /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`${EDIT_VENDOR_URL}/:roomId`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><AddEditVendor /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={`${VIEW_VENDOR_URL}/:roomId`} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><ViewVendor /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
            <Route path={DEVICES_LIST_URL} element={(accessToken !== "" && is_login !== "no") ? <AuthLayout><Device /></AuthLayout> : <Navigate replace to={LOGIN_URL} />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
    </div>
  </WebSocketContext.Provider>);
}

export default App;
