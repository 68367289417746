/* eslint-disable */
import { ActionTypes } from "../actions/adminActions";

const initailData = {
    is_login: "no",
    user_id: "",
    sidebaropen: false,
    selfInfo: {},
    accessToken: "",
    sessionlist: [],
    dashboardDetails: {},
};

export const adminReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.IS_LOGIN:
            return {
                ...state,
                is_login: action.payload,
            };
        case ActionTypes.GET_USER_ID:
            return {
                ...state,
                user_id: action.payload,
            };
        case ActionTypes.SELF_DETAILS:
            return {
                ...state,
                selfInfo: action.payload,
            };
        case ActionTypes.EDIT_SELF_DETAILS:
            return {
                ...state,
                selfInfo: action.payload,
            };
        case ActionTypes.GET_DASHBOARD_COUNT:
            return {
                ...state,
                dashboardDetails: action.payload,
            };
        case ActionTypes.SIDEBAR_CLOSE:
            return {
                ...state,
                sidebaropen: action.payload,
            };
        case ActionTypes.GET_SESSION_LIST:
            return {
                ...state,
                sessionlist: action.payload,
            };
        case ActionTypes.DELETE_SESSION_LIST:
            const OldSessionData = [...state.sessionlist];
            const AfterDeletedSessionList = [];
            OldSessionData && OldSessionData.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return AfterDeletedSessionList.push(elm);
                }
            })
            return {
                ...state,
                sessionlist: AfterDeletedSessionList,
            };
        case ActionTypes.GET_ACCESSTOKEN:
            return {
                ...state,
                accessToken: action.payload,
            };
        case ActionTypes.ADMIN_LOGOUT:
            return {
                ...initailData,
            };
        default:
        return state;
    }
};