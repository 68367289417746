/* eslint-disable */
import React, { useEffect } from "react";
import VendorIcon from "../../assets/img/vendor.png";
import CategoryIcon from "../../assets/img/category.png";
import PorductIcon from "../../assets/img/product.png";
import OrderIcon from "../../assets/img/order.png";
import CustomerIcon from "../../assets/img/customer.png";
import UsersIcon from "../../assets/img/users.png";
import { Image } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars-2';
import moment from "moment/moment";
import { useNavigate } from "react-router";
import { USER_MANAGEMENT_URL, VENDOR_MANAGEMENT_URL } from "../Shared/constant";
import AdminDashBoardAPI from "../../Api/AdminDashBoardAPI";
import CommonLogout from "../Common/CommonLogout";
import { useSelector, useDispatch } from "react-redux";
import { getDashBoardCount } from "../../redux/actions/adminActions";

const Dashboard = () => {
    const  Dispatch = useDispatch();
    const { accessToken, dashboardDetails } = useSelector((state) => state.adminReducers);
    const Navigate = useNavigate();

    useEffect(() => {
        if (accessToken) {
            fetchData();
        }
    }, [accessToken]);

    const fetchData = async () => {
        // Get dashboard count
        const dashboardList = await AdminDashBoardAPI(accessToken);
        if (dashboardList?.status === 200) {
            Dispatch(getDashBoardCount(dashboardList))
        } else if (dashboardList?.detail === "Token expired.") {
            CommonLogout();
        } else if (dashboardList?.detail === "User is inactive.") {
            CommonLogout();
        } else {
            // toast.error(categoryList.data.error);
        };
    };

    useEffect(() => {
        const google = window.google;
        google.charts.load('current', {'packages':['corechart', 'bar']});
        google.charts.setOnLoadCallback(drawChartUsers);
        google.charts.setOnLoadCallback(drawChartUsersCount);
        google.charts.setOnLoadCallback(drawChartProduct);
        google.charts.setOnLoadCallback(drawChartOrders);
        google.charts.setOnLoadCallback(drawChartUsersChart);
        google.charts.setOnLoadCallback(drawChartCustomerChart);

        // user piechart ********************************
        console.log("dashboardDetails==========>", dashboardDetails);
        function drawChartUsers() {
            var data = google.visualization.arrayToDataTable([
              ['Task', 'Hours per Day'],
              ['Admin', dashboardDetails?.users_count?.admin],
              ['Vendor', dashboardDetails?.users_count?.vendor],
            ]);
      
            var options = {
              title: '',
              is3D: true
            };
      
            var chart = new google.visualization.PieChart(document.getElementById('piechartUsers'));
            chart.draw(data, options);
        };

        // user piechart ********************************
        function drawChartUsersCount() {
            var data = google.visualization.arrayToDataTable([
              ['Task', 'Hours per Day'],
              ['Has Category', dashboardDetails?.users_count?.vendor_has_category],
              ['Has Customer', dashboardDetails?.users_count?.vendor_has_customer],
              ['Has Discount', dashboardDetails?.users_count?.vendor_has_discount]
            ]);
      
            var options = {
              title: '',
              is3D: true
            };
      
            var chart = new google.visualization.PieChart(document.getElementById('piechartUsersCatgoryCount'));
            chart.draw(data, options);
        };
      
        // six months product list********************************
        const getLastSixMonth = [];
        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var today = dashboardDetails?.past_six_month_product_count?.past_six_month_count.length > 0 ? new Date(dashboardDetails?.past_six_month_product_count?.past_six_month_count[dashboardDetails?.past_six_month_product_count?.past_six_month_count?.length - 1]?.month) : new Date();
        var d;
        var month;
        for(var i = 6; i >= 0; i -= 1) {
            d = new Date(today.getFullYear(), today.getMonth() - i, 1);
            month = monthNames[d.getMonth()];
            getLastSixMonth.push(`${month} 1, ${moment(d).format('YYYY')}`);
        };
        var EmptyMonthArray = [
            [getLastSixMonth[0].split(" ")[0], 1],
            [getLastSixMonth[1].split(" ")[0], 1],
            [getLastSixMonth[2].split(" ")[0], 1],
            [getLastSixMonth[3].split(" ")[0], 1],
            [getLastSixMonth[4].split(" ")[0], 1],
            [getLastSixMonth[5].split(" ")[0], 1],
            [getLastSixMonth[6].split(" ")[0], 1]
        ];
        function drawChartProduct() {
            const PastSixPamentArrays = [['Year', 'count']];
            if(dashboardDetails?.past_six_month_product_count?.past_six_month_count.length > 0) {
                getLastSixMonth.filter((data_, index) => {
                    const CurrentBar = dashboardDetails?.past_six_month_product_count?.past_six_month_count[index];
                    if(data_.split(" ")[0] !== moment.utc(CurrentBar?.month).local().format('LL').split(" ")[0]) {
                        PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), 0]);    
                    } else {
                        dashboardDetails?.past_six_month_product_count?.past_six_month_count.filter((elm) => {
                            PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), elm?.count]);
                        })
                    }
                });
            } else {
                getLastSixMonth.filter((data_, index) => {
                    const CurrentBar = EmptyMonthArray[index];
                    PastSixPamentArrays.push([moment.utc(CurrentBar).local().format('MMM D, YYYY'), 1]);
                });
            }
    
            var data = google.visualization.arrayToDataTable(PastSixPamentArrays);
            
            var options = {
                chart: {
                    // title: 'Company Performance',
                    // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                }
            };
            var chart = new google.charts.Bar(document.getElementById('columnchart_material'));
            chart.draw(data, google.charts.Bar.convertOptions(options));
        };


        // six months orders list********************************
        const getLastSixMonthOrder = [];
        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var todays = dashboardDetails?.past_six_month_order_count?.past_six_month_count.length > 0 ? new Date(dashboardDetails?.past_six_month_order_count?.past_six_month_count[dashboardDetails?.past_six_month_order_count?.past_six_month_count?.length - 1]?.month) : new Date();
        var ds;
        var month;
        for(var i = 6; i >= 0; i -= 1) {
            ds = new Date(todays.getFullYear(), todays.getMonth() - i, 1);
            month = monthNames[ds.getMonth()];
            getLastSixMonthOrder.push(`${month} 1, ${moment(ds).format('YYYY')}`);
        };
        function drawChartOrders() {
            const PastSixPamentArrays = [['Year', 'count']];
            if(dashboardDetails?.past_six_month_order_count?.past_six_month_count.length > 0) {
                getLastSixMonthOrder.filter((data_, index) => {
                    const CurrentBar = dashboardDetails?.past_six_month_order_count?.past_six_month_count[index];
                    if(data_.split(" ")[0] !== moment.utc(CurrentBar?.month).local().format('LL').split(" ")[0]) {
                        PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), 0]);    
                    } else {
                        dashboardDetails?.past_six_month_order_count?.past_six_month_count.filter((elm) => {
                            PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), elm?.count]);
                        })
                    }
                });
            } else {
                getLastSixMonthOrder.filter((data_, index) => {
                    const CurrentBar = EmptyMonthArray[index];
                    PastSixPamentArrays.push([moment.utc(CurrentBar).local().format('MMM D, YYYY'), 1]);
                });
            }
    
            var data = google.visualization.arrayToDataTable(PastSixPamentArrays);
            
            var options = {
                chart: {
                    // title: 'Company Performance',
                    // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                }
            };
            var chart = new google.charts.Bar(document.getElementById('columnchart_orders'));
            chart.draw(data, google.charts.Bar.convertOptions(options));
        }


        // six months users list********************************
        const getLastSixMonthUsers = [];
        var monthNamess = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var todayz = dashboardDetails?.past_six_month_user_count?.past_six_month_count.length > 0 ? new Date(dashboardDetails?.past_six_month_user_count?.past_six_month_count[dashboardDetails?.past_six_month_user_count?.past_six_month_count?.length - 1]?.month) : new Date();
        var dss;
        var monthss;
        for(var i = 6; i >= 0; i -= 1) {
            dss = new Date(todayz.getFullYear(), todayz.getMonth() - i, 1);
            monthss = monthNamess[dss.getMonth()];
            getLastSixMonthUsers.push(`${monthss} 1, ${moment(dss).format('YYYY')}`);
        };
        function drawChartUsersChart() {
            const PastSixPamentArrays = [['Year', 'count']];
            if(dashboardDetails?.past_six_month_user_count?.past_six_month_count.length > 0) {
                getLastSixMonthUsers.filter((data_, index) => {
                    const CurrentBar = dashboardDetails?.past_six_month_user_count?.past_six_month_count[index];
                    if(data_.split(" ")[0] !== moment.utc(CurrentBar?.month).local().format('LL').split(" ")[0]) {
                        PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), 0]);    
                    } else {
                        dashboardDetails?.past_six_month_user_count?.past_six_month_count.filter((elm) => {
                            PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), elm?.count]);
                        })
                    }
                });
            } else {
                getLastSixMonthUsers.filter((data_, index) => {
                    const CurrentBar = EmptyMonthArray[index];
                    PastSixPamentArrays.push([moment.utc(CurrentBar).local().format('MMM D, YYYY'), 1]);
                });
            }

            var data = google.visualization.arrayToDataTable(PastSixPamentArrays);
            
            var options = {
                chart: {
                    // title: 'Company Performance',
                    // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                }
            };
            var chart = new google.charts.Bar(document.getElementById('columnchart_users'));
            chart.draw(data, google.charts.Bar.convertOptions(options));
        }

        // six months customer list********************************
        const getLastSixMonthCustomer = [];
        var monthNamess = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var todayz = dashboardDetails?.past_six_month_customer_count?.past_six_month_count.length > 0 ? new Date(dashboardDetails?.past_six_month_customer_count?.past_six_month_count[dashboardDetails?.past_six_month_customer_count?.past_six_month_count?.length - 1]?.month) : new Date();
        var dss;
        var monthss;
        for(var i = 6; i >= 0; i -= 1) {
            dss = new Date(todayz.getFullYear(), todayz.getMonth() - i, 1);
            monthss = monthNamess[dss.getMonth()];
            getLastSixMonthCustomer.push(`${monthss} 1, ${moment(dss).format('YYYY')}`);
        };
        function drawChartCustomerChart() {
            const PastSixPamentArrays = [['Year', 'count']];
            if(dashboardDetails?.past_six_month_customer_count?.past_six_month_count.length > 0) {
                getLastSixMonthCustomer.filter((data_, index) => {
                    const CurrentBar = dashboardDetails?.past_six_month_customer_count?.past_six_month_count[index];
                    if(data_.split(" ")[0] !== moment.utc(CurrentBar?.month).local().format('LL').split(" ")[0]) {
                        PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), 0]);    
                    } else {
                        dashboardDetails?.past_six_month_customer_count?.past_six_month_count.filter((elm) => {
                            PastSixPamentArrays.push([moment.utc(data_).local().format('ll'), elm?.count]);
                        })
                    }
                });
            } else {
                getLastSixMonthCustomer.filter((data_, index) => {
                    const CurrentBar = EmptyMonthArray[index];
                    PastSixPamentArrays.push([moment.utc(CurrentBar).local().format('MMM D, YYYY'), 1]);
                });
            }

            var data = google.visualization.arrayToDataTable(PastSixPamentArrays);
            
            var options = {
                chart: {
                    // title: 'Company Performance',
                    // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                }
            };
            var chart = new google.charts.Bar(document.getElementById('columnchart_customer'));
            chart.draw(data, google.charts.Bar.convertOptions(options));
        }
    }, []);

    return(<section className="dashboard_wrapper">
        <Scrollbars 
            style={{ height: "calc(100% - 0px)"}} 
            className="ScrollbarsWrapper"
            renderView={props => <div {...props} className="view"/>}
        >
            <div className="dashBordcountwraps">
                <div className="dashboardcolumn" style={{ backgroundColor: "rgb(14, 57, 182, 0.1)", borderColor: "rgb(14, 57, 182, 0.4)" }}>{/* onClick={() => Navigate(USER_MANAGEMENT_URL)} */}
                    <div className="iconfor" style={{ backgroundColor: "rgb(14, 57, 182)", padding: "10px" }}>
                        <Image src={UsersIcon} al="users" />
                    </div>
                    <div className="dashcoldetails">
                        <h5>Total Users</h5>
                        <h1>{dashboardDetails?.users_count?.total}</h1>
                    </div>
                    <div className="dash_columgnset">
                        <div className="columndahs">
                            <label>Active</label>
                            {dashboardDetails?.users_count?.active}
                        </div>
                        <div className="columndahs">
                            <label>Inactive</label>
                            {dashboardDetails?.users_count?.inactive}
                        </div>
                    </div>
                </div>
                <div className="dashboardcolumn" onClick={() => Navigate(VENDOR_MANAGEMENT_URL)} style={{ backgroundColor: "rgb(250, 90, 126, 0.1)", borderColor: "rgb(250, 90, 126, 0.4)" }}>
                    <div className="iconfor" style={{ backgroundColor: "#fa5a7e", padding: "10px" }}>
                        <Image src={VendorIcon} al="Vendors" />
                    </div>
                    <div className="dashcoldetails">
                        <h5>Total Vendors</h5>
                        <h1>{dashboardDetails?.total_counts?.total_vendors}</h1>
                    </div>
                </div>
                <div className="dashboardcolumn" style={{ backgroundColor: "rgb(254, 148, 122, 0.1)", borderColor: "rgb(254, 148, 122,0.4)" }}>
                    <div className="iconfor" style={{ backgroundColor: "#fe947a", padding: "12px 12px" }}>
                        <Image src={CategoryIcon} al="Category" />
                    </div>
                    <div className="dashcoldetails">
                        <h5>Total Category</h5>
                        <h1>{dashboardDetails?.total_counts?.total_categories}</h1>
                    </div>
                </div>
                <div className="dashboardcolumn" style={{ backgroundColor: "rgb(60, 215, 87, 0.1)", borderColor: "rgb(60, 215, 87,0.4)" }}>
                    <div className="iconfor" style={{ backgroundColor: "#3cd757", padding: "10px" }}>
                        <Image src={PorductIcon} al="Product" />
                    </div>
                    <div className="dashcoldetails">
                        <h5>Total Product</h5>
                        <h1>{dashboardDetails?.total_counts?.total_products}</h1>
                    </div>
                </div>
                <div className="dashboardcolumn" style={{ backgroundColor: "rgb(191, 131, 255, 0.1)", borderColor: "rgb(191, 131, 255,0.4)" }}>
                    <div className="iconfor" style={{ backgroundColor: "#bf83ff", padding: "7px" }}>
                        <Image src={OrderIcon} al="Orders" />
                    </div>
                    <div className="dashcoldetails">
                        <h5>Total Orders</h5>
                        <h1>{dashboardDetails?.total_counts?.total_orders}</h1>
                    </div>
                </div>
                <div className="dashboardcolumn" style={{ backgroundColor: "rgb(135, 15, 163, 0.1)", borderColor: "rgb(135, 15, 163,0.4)" }}>
                    <div className="iconfor" style={{ backgroundColor: "#870fa3", padding: "10px" }}>
                        <Image src={CustomerIcon} al="Customers" />
                    </div>
                    <div className="dashcoldetails">
                        <h5>Total Customers</h5>
                        <h1>{dashboardDetails?.total_counts?.total_customers}</h1>
                    </div>
                </div>
            </div>
            <div className="graph_wrapper">
                <div className="graphColumn">
                    <h4>Users</h4>
                    <div className="graphcols_wrapper">
                        <div className="row">
                            <div className="col-md-6">
                                <div id="piechartUsers" className="graphcomnset" style={{ width: '900px', height: '500px' }}></div>
                            </div>
                            <div className="col-md-6">
                                <div id="piechartUsersCatgoryCount" className="graphcomnset" style={{ width: '900px', height: '500px' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="graphColumn">
                    <h4>Six Month Users List</h4>
                    <div id="columnchart_users" className="graphcomnset" style={{ width: '900px', height: '500px' }}></div>
                </div>
                <div className="graphColumn">
                    <h4>Six Month Product List</h4>
                    <div id="columnchart_material" className="graphcomnset" style={{ width: '900px', height: '500px' }}></div>
                </div>
                <div className="graphColumn">
                    <h4>Six Month Orders List</h4>
                    <div id="columnchart_orders" className="graphcomnset" style={{ width: '900px', height: '500px' }}></div>
                </div>
                <div className="graphColumn">
                    <h4>Six Month Customer List</h4>
                    <div id="columnchart_customer" className="graphcomnset" style={{ width: '900px', height: '500px' }}></div>
                </div>
            </div>
        </Scrollbars>
    </section>)
};

export default Dashboard;